import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const SplitContentCss = withTheme(() => (
  <Global
    styles={css`
      ${'' /* Styles go here */}
    `}
  />
))

export default SplitContentCss
