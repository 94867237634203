import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const FormCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .form-page-form {
        .leshen-input-text {
          border-color: #e6e6e6;
        }
      }

      .availability-form {
        .leshen-form {
          margin-bottom: ${theme.spacing.md};

          @media screen and (max-width: ${theme.breakpoints.sm}) {
            margin-bottom: ${theme.spacing.xs};
          }

          input[name='zip_postal'] {
            border-top: 0;
            border-left: 0;
            border-right: 0;
            border-radius: 0;
            border-bottom: 2px solid ${theme.colors.primary.base.hex};
            padding-left: 0;
            margin-right: ${theme.spacing.md};

            @media screen and (min-width: ${theme.breakpoints.sm}) {
              max-width: 155px;
            }

            @media screen and (max-width: ${theme.breakpoints.sm}) {
              max-width: 210px;
              margin-left: auto;
              margin-right: auto;
              text-align: center;
              margin-bottom: ${theme.spacing.xs};
            }
          }

          input[name='zip_postal']:focus {
            outline: none;
          }

          .submit-button {
            border-radius: 24px !important;

            @media screen and (max-width: ${theme.breakpoints.sm}) {
              max-width: 124px;
              margin-right: auto;
              margin-left: auto;
            }
          }

          .group {
            display: flex;
            flex-direction: row;

            @media screen and (max-width: ${theme.breakpoints.sm}) {
              flex-direction: column;
            }
          }
        }
      }
    `}
  />
))

export default FormCss
