import React from 'react'
import PropTypes from 'prop-types'
import Wrapper from './src/components/Wrapper'

// wrapRootElement is part of the Gatsby API, and thus cannot be the default export
/* eslint-disable import/prefer-default-export, react/jsx-filename-extension */
export const wrapRootElement = ({ element }) => <Wrapper>{element}</Wrapper>

export const onRouteUpdate = ({ location }) => {
  // Check if UTM parameters are present in the URL
  const utmParams = new URLSearchParams(location.search)

  if (utmParams.has('utm_source')) {
    // Store UTM parameters in localStorage
    localStorage.setItem('utm_source', utmParams.get('utm_source') || '')
  }
}

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
}
