import React from 'react'
import { withTheme } from '@emotion/react'
import FooterStyles from './Footer.styles'
import GlobalCss from './GlobalCss.styles'
import BillboardCss from './Billboard.styles'
import SplitContentCss from './SplitContent.styles'
import ColumnsCss from './Columns.styles'
import BannerCss from './Banner.styles'
import HeaderStyles from './Header.styles'
import TableCss from './Table.styles'
import StateDropdownCSS from './StateDropdown.styles'
import FormCss from './Form.styles'
import LinkButtonCss from './LinkButton.styles'
import StickyCtaCss from './StickyCTA.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
    <HeaderStyles />
    <BillboardCss />
    <SplitContentCss />
    <FooterStyles />
    <ColumnsCss />
    <BannerCss />
    <TableCss />
    <StateDropdownCSS />
    <FormCss />
    <LinkButtonCss />
    <StickyCtaCss />
  </>
))

export default GlobalStyles
