import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const GlobalCss = withTheme(({ theme }) => (
  <Global
    styles={css`
        .leshen-typography-h1,
        .leshen-typography-h2,
        .leshen-typography-h3,
        .leshen-typography-h4,
        .leshen-typography-h5,
        .leshen-typography-h6 {
            font-weight: 700 !important;
            margin-bottom: ${theme.spacing.md};

            .font-weight-normal {
                font-weight: 400 !important;
            }

            &.no-margin-bottom {
                margin-bottom: 0 !important;
            }
        }

        .leshen-list-item {
            font-weight: 400 !important;
            font-size: 1rem;
            color: ${theme.colors.dark.base.hex};
            margin-bottom: ${theme.spacing.md};
        }

        .leshen-listunordered {
            .leshen-list-item {
                svg {
                    path {
                        fill: ${theme.colors.primary.base.hex};
                    }
                }
            }
        }

        a {
            text-decoration: none;
        }

        strong[color='primary'] {
            color: ${theme.colors.primary.base.hex};
        }

        .header-text {
            color: ${theme.colors.dark.base.hex};
        }

        .text-light {
            color: ${theme.colors.light.base.hex};
        }

        .bg-light {
            background-color: ${theme.colors.light.base.hex};
        }

        .bg-primary {
            background-color: ${theme.colors.primary.base.hex};
        }

        .card {
            border-radius: 10px;
            padding: 24px;

            @media screen and (max-width: ${theme.breakpoints.md}) {
                width: 100%;
            }

            .content {
                .product-icons {
                    margin-bottom: ${theme.spacing.md} !important;
                }
            }

            &.text-align-left-small {
                @media screen and (max-width: ${theme.breakpoints.sm}) {
                    text-align: left;
                }
            }

            &.center-content {
                .content {
                    display: flex;
                    text-align: center;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    height: 100%;
                    width: 100%;

                    .top-content {
                        display: flex;
                        align-items: center;
                        flex-direction: column;
                    }
                }
            }

            &.justify-content-center {
                .content {
                    margin-bottom: 0;
                    justify-content: center;
                }

                .leshen-typography-h4 {
                    margin-bottom: 0 !important;
                }
            }

            &.box-shadow {
                box-shadow: 5px 10px 36px -5px rgba(0, 0, 0, 0.17);
            }

            &.text-align-left {
                text-align: left !important;
            }

            .buttons {
                display: flex;
                gap: 16px;
                justify-content: flex-start;
                margin-right: auto;

                .leshen-link {
                    button {
                        min-width: 100px;
                    }
                }
            }
        }

        .card:hover {
            transform: scale(102%);
            transition: 200ms ease;
        }

        .inline {
            display: inline-block;
        }

        button[color='light'] {
            color: ${theme.colors.primary.base.hex};

            svg {
                path {
                    fill: ${theme.colors.primary.base.hex};
                }
            }
        }

        button[color='light']:hover {
            color: ${theme.colors.primary.base.hex};
        }

        .background-color-lightGray,
        .bg-light-gray {
            background-color: ${theme.colors.lightGray.base.hex};
        }

        .leshen-variable-content {
            .content {
                margin-bottom: 16px;
            }
        }

        .leshen-link {
            text-decoration: none;

            &.large {
                font-size: 32px;
                font-weight: bold;
            }
        }

        .leshen-link:hover {
            text-decoration: underline;
        }

        .margin-bottom-md {
            margin-bottom: ${theme.spacing.md} !important;
        }

        .margin-top-md {
            margin-top: ${theme.spacing.md} !important;
        }

        .margin-x-auto {
            margin-left: auto;
            margin-right: auto;
        }

        .margin-left-auto {
            margin-left: auto;
        }

        .margin-x-auto-small {
            @media screen and (max-width: ${theme.breakpoints.md}) {
                margin-left: auto;
                margin-right: auto;
            }
        }

        .leshen-accordion-item {
            a {
                text-decoration: none;
                color: ${theme.colors.primary.base.hex} !important;
            }

            a:hover {
                text-decoration: underline;
            }
        }

        .leshen-form-submission-message {
            text-align: center;
        }

        // helper styles to put out fires. these probably won't be needed in the future.

        .center-content {
            display: flex;
            justify-content: center;
        }

        .small-section-padding {
            padding: 32px 16px;
        }

        .show-on-mobile {
            display: block;
            @media screen and (min-width: ${theme.breakpoints.lg}) {
                display: none;
            }
        }

        .show-on-desktop {
            display: none;
            @media screen and (min-width: ${theme.breakpoints.lg}) {
                display: block;
            }
        }

        .bottom-bar {
            button[color='primary'] {
                span {
                    color: white !important;
                }
            }
        }

        a {
            &:link {
                color: unset;
            }
        }

        .centered {
            margin: auto;
        }
    `}
  />
))

export default GlobalCss
