import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const ColumnsStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-columns {
        @media screen and (max-width: ${theme.breakpoints.lg}) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }

      .icon-two-column {
        max-width: 1000px;
        display: flex !important;
        flex-direction: column;
        justify-content: center;
        padding: 0;
        grid-gap: 0 !important;

        @media screen and (min-width: ${theme.breakpoints.md}) {
          margin-left: auto;
          margin-right: auto;
          flex-direction: row;
          justify-content: flex-start;
        }

        .icon-column {
          display: flex;
          align-items: center;
          margin-bottom: 0;

          @media screen and (min-width: ${theme.breakpoints.lg}) {
            max-width: 164px;
            flex-direction: row;
            align-items: flex-start;
          }
        }

        .content-column {
          max-width: 744px;
        }
      }
    `}
  />
))

export default ColumnsStyles
