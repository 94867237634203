import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const LinkButtonCss = withTheme(() => (
  <Global
    styles={css`
      .unstyled {
        .leshen-link,
        .leshen-link-button {
          box-shadow: unset;
          background-color: unset;
          color: unset;
          padding: 0;
          font-size: inherit;
          line-height: unset;

          button {
            &:hover,
            &:focus,
            &:visited {
              padding: 0;
              background-color: unset;
              color: unset;
            }
          }
        }
      }
    `}
  />
))

export default LinkButtonCss
