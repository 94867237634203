import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const BillboardCss = withTheme(({ theme }) => (
  <Global
    styles={css`
      .leshen-hero {
        .leshen-typography-h1,
        .leshen-typography-h2,
        .leshen-typography-h3,
        .leshen-typography-h4,
        .leshen-typography-h5,
        .leshen-typography-h6,
        .leshen-typography-body {
          color: ${theme.colors.light.base.hex};
        }

        .leshen-typography-h1[color='dark'],
        .leshen-typography-h2[color='dark'],
        .leshen-typography-h3[color='dark'],
        .leshen-typography-h4[color='dark'],
        .leshen-typography-h5[color='dark'],
        .leshen-typography-h6[color='dark'],
        .leshen-typography-body[color='dark'] {
          color: ${theme.colors.dark.base.hex};
        }

        @media screen and (max-width: ${theme.breakpoints.md}) {
          background: linear-gradient(
            45deg,
            ${theme.colors.primaryAlt.base.hex},
            ${theme.colors.secondary.base.hex}
          );
        }

        &.mobile-background-dark {
          @media screen and (max-width: ${theme.breakpoints.md}) {
            padding-top: 0;
            background: ${theme.colors.dark.base.hex};
          }
        }

        &.reverse-content-desktop {
          @media screen and (min-width: ${theme.breakpoints.md}) {
            padding-left: 50%;
            padding-right: calc(2.2222222222222223vw + 21.333333333333332px);
          }
        }

        &.background-image-position-right {
          .background-image {
            img {
              object-position: 75%;
            }
          }
        }

        &.background-image-position-top {
          .background-image {
            img {
              object-position: top;
            }
          }
        }
      }

      .card {
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;

        .leshen-typography-h4[color='primary'] {
          color: ${theme.colors.primary.base.hex};
        }

        @media screen and (min-width: ${theme.breakpoints.sm}) {
          max-width: 450px;
          align-items: flex-start;
          text-align: left;
        }
      }
    `}
  />
))

export default BillboardCss
