import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const BannerStyles = withTheme(({ theme }) => (
  <Global
    styles={css`
      .cta-banner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @media screen and (min-width: ${theme.breakpoints.sm}) {
          button {
            margin: auto;
          }
        }
      }
    `}
  />
))

export default BannerStyles
