import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const DualStickyCTAStyles = withTheme(() => (
  <Global
    styles={css`
      .sticky-cta,
      .dual-sticky-cta {
      }
    `}
  />
))
export default DualStickyCTAStyles
